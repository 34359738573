* {
  box-sizing: border-box;
}

body {
  background-color: #f1f1f1;
}

#regForm {
  background-color: #ffffff;
  margin: 100px auto;
  font-family: sans-serif;
  padding: 40px;
  width: 80%;
  min-width: 300px;
}
.ankit{
  display:flex;
    justify-content:center;
    align-items:center;
    z-index:5;
    position:absolute;
    margin-left:32%;
    /* margin-top:105%; */
    flex-direction: column;
}
@media screen and (max-width: 700px) {
  #regForm {
    background-color: #ffffff;
    margin: 100px auto;
    font-family: sans-serif;
    padding: 40px;
    width: 100%;
    min-width: 300px;
  }
  .ankit{
    display:flex;
      justify-content:center;
      align-items:center;
      z-index:5;
      position:absolute;
      margin-left:28%;
      /* margin-top:3000px; */
      flex-direction: column;
  }
}

h1 {
  text-align: center;
}

input {
  padding: 10px;
  width: 100%;
  font-size: 17px;
  font-family: sans-serif;
  border: 1px solid #aaaaaa;
  text-transform: capitalize;

}

/* Mark input boxes that gets an error on validation: */
input.invalid {
  background-color: #ffdddd;
}

/* Hide all steps by default: */
.tab {
  display: none;
}

.tablinks {
  width: 150px;
  background-color: rgb(255, 119, 0);
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 17px;
  font-family: Raleway;
  cursor: pointer;
}

button {
  background-color: #04aa6d;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 17px;
  font-family: Raleway;
  cursor: pointer;
}

button:hover {
  opacity: 0.8;
}

#prevBtn {
  background-color: #bbbbbb;
}

/* Make circles that indicate the steps of the form: */
.step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

.step.active {
  opacity: 1;
}

/* Mark the steps that are finished and valid: */
.step.finish {
  background-color: #04aa6d;
}

.form-label {
  font-size: 19px;
}

body {
  font-family: Arial;
}

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.checkbox-wrapper-17 input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.checkbox-wrapper-17 label {
  --size: 50px;

  cursor: pointer;
  width: var(--size);
  height: calc(var(--size) / 2);
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

.checkbox-wrapper-17 label:after {
  content: "";
  position: absolute;
  top: 6%;
  left: 2.5%;
  width: calc(50% - 5%);
  height: calc(100% - 11%);
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.checkbox-wrapper-17 input:checked+label {
  background: #bada55;
}

.checkbox-wrapper-17 input:checked+label:after {
  left: calc(100% - 2.5%);
  transform: translateX(-100%);
}

.checkbox-wrapper-17 label:active:after {
  width: 55%;
}

/* Styles for the modal */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(164, 77, 77, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 400px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* table css */
table {
  border-collapse: collapse;
  width: 100%;
  background-color: #F2F2F2;
}

th, td {
  text-align: center;
  padding: 8px;
  border: 1px solid black;
}

tr:nth-child(even) {
  background-color: #F2F2F2;
}


/* scroll view ...... */
.scrolling-box {
  display: block;
  width: 100%;
  height: auto;
  padding: 1em;
  overflow-y: scroll;
  text-align: center;
}



.loader {
  position: relative;
  width: 64px;
  height: 64px;
  background-color: rgba(0, 0, 0, 0.5);
  transform: rotate(45deg);
  overflow: hidden;
}
.loader:after{
  content: '';
  position: absolute;
  inset: 8px;
  margin: auto;
  background: #222b32;
}
.loader:before{
  content: '';
  position: absolute;
  inset: -15px;
  margin: auto;
  background: #de3500;
  animation: diamondLoader 2s linear infinite;
}
@keyframes diamondLoader {
  0%  ,10% {
    transform: translate(-64px , -64px) rotate(-45deg)
  }
  90% , 100% {
    transform: translate(0px , 0px) rotate(-45deg)
  }
}